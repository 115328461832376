.flip-clock {
  --fcc-background: #2c2f46;
  margin-left: auto;
  margin-right: auto;
  --fcc-digit-font-size: 2.6rem; /* font size of digit */
  --fcc-digit-block-width: 2.6rem; /* width of digit card */
  --fcc-digit-block-height: 4rem; /* height of digit card, highly recommend in even number */
}
@media (max-width: 70rem) {
  .flip-clock {
    --fcc-digit-font-size: 1.75rem; /* font size of digit */
    --fcc-digit-block-width: 1.75rem; /* width of digit card */
    --fcc-digit-block-height: 2.625rem; /* height of digit card, highly recommend in even number */
  }
}

@media (max-width: 50rem) {
  .flip-clock {
    --fcc-background: #47362d;
  }
}
